import React, { useState } from "react";
import parse from "html-react-parser"
import Layout from "@components/layout"
import LazyLoad from 'react-lazy-load';

// 言語用Jsonファイル
import langEn from "@content/locales/en/blog-archive.json";
import langJa from "@content/locales/ja/blog-archive.json";
// Import images
import kijimore from "@images/kiji_more.svg"

// 共通Link用ファイル
import { LinkSetting } from "@components/link-setting.js";
import { SeoContents } from "@components/seo-data.js" // SEO,meta情報設定

import { GraphqlArchivesArray } from "@components/graphQL-archivesData.js";
import { GraphqlBannerArray } from "@components/graphQL-bannerData.js" // banner設定

// ユニークの変数名
const BlogPostArchive = (data) => {

  const postData = data.pageContext;
  
  // // 言語設定
  const setLang = postData.lang;
  let postLang, langUrl, categUri;
  setLang === 'en'? postLang = langEn: postLang = langJa;
  setLang === 'en'? langUrl = '/': langUrl = '/' + setLang + '/';
  categUri = postData.uri;
  const selectType = 'blog'

  // Seo情報設定
  const seoTag = SeoContents( 
    categUri, setLang, 
    postLang[ selectType + "-page-title" ], 
    postLang[ selectType + "-page-description"],
    postLang[ selectType + "-page-keywords"],
    "archive", ''
  );
  //seo情報要確認　num消したから
  
  // アーカイブ画像情報取得 後にnode.jsから取得
  const [getData] = useState(() => GraphqlArchivesArray('post-archive', postData.category.slug, setLang));
  // console.log(getData.categoryArray)
  // console.log(getData.dataArray)
  
  // バナー画像情報取得
  const [pageBannerData] = useState(() => GraphqlBannerArray("page-banner", setLang));

  // リンク
  const [signPath1] = useState(() => LinkSetting( setLang ).Register);

  // 日時形式変換用function
  function formatDate(dateString, lang) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const monthIndex = date.getMonth();
    const day = date.getDate();
    return lang === 'ja'? `${year}年${monthIndex + 1}月${day}日`: dateString;
  }

  // 追加表示機能
  const [count, setCount] = useState(0);
  const increaseCount = () => { if (count < getData.dataArray.length - 1) { // 追加制御
      setCount(count + 1); // 増加
  }};
  const resetCount = () => { // リセット制御
    setCount(0); // リセット
    const element = document.getElementById('scroll-target'); // トップへスクロール
    if (element) { 
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const handleClick = () => { // 追加/リセット切替
    if (count !== getData.dataArray.length - 1) { increaseCount(); } else { resetCount(); }
  };

  // 文字数制限function(テキスト, 制限文字数)
  const setLen = 25;
  function shortText(text, len) {
    let shortText;
    let counts = 0;
    for (let char of text) { // アルファベットは2/3文字,それ以外は1文字として計算
      // counts += (char >= 'a' && char <= 'z') || (char >= 'A' && char <= 'Z') ? 1/3 : 1;
      counts += /[a-zA-Z0-9!@#$%^&*(),.?":{}|<>]/.test(char) ? 1/3 : 1;
    }
    counts = Math.ceil(counts); // 小数点第一位を切り上げ
    if ( counts > len ) {shortText = text.substr( 0, len) + ' ...';} else {shortText = text;}
    return shortText
  }

  return (
    <>
      {seoTag}
      <Layout isHomePage>
        <div className="FV pt-5 pb-8 bg-fixed" langType={setLang}>
          <div className="max-w-xl mx-auto px-6 md:max-w-5xl md:pt-5 pt-2">
            <div className=" text-left h1_filed">
              <h1 className="md:text-[60px] text-[35px] font-semibold">{postLang[selectType + "-title"]}</h1>
              <div className="text-base">
                <p>{postLang[selectType + "-txt1"]}{postLang[selectType + "-txt2"]}<br></br></p>
              </div>
            </div>
          </div>
        </div>

        <div id="scroll-target" className="max-w-5xl mx-auto pt-5 md:pr-6 flex md:flex-nowrap flex-wrap md:justify-between justify-center">
          <div className="max-w-[740px] w-full">
            <div className="max-w-xl mx-auto pt-5 md:max-w-5xl px-6">
              <div className="text-left  h-1/2 mb-10">
                <h2 className="tracking-wide font-semibold text-[26px] md:text-[40px]">{setLang === 'ja'? `${postData.category.jaName} 一覧`: postData.category.enName}</h2>
              </div>
            </div>

            {/* 記事一覧 */}
            <div className="px-6 mx-auto md:max-w-5xl pb-4 md:flex max-w-xl md:flex-wrap md:justify-start items-center md:max-w-5xl">
              {(() => {const elements = []; for (let i = 0; i <= count; i++) {elements.push(
                <>
                  {getData.dataArray[i].map((result) => 
                    <li className="md:w-[30%] mx-[1.6%] list-none mb-[30px]">
                      <article className="post-list-item !m-0 h-[100%]">
                        <a href={`${langUrl}blog/${result.node.slug}/`} className={"Campaign w-[100%] md:index_campaign_btn_pc index_campaign_btn_sp"}>
                          {/* 画像部分 */}
                          <div className="relative w-full overflow-hidden aspect-[4/1.5]">
                            <p style={{ backgroundColor: result.node.archiveImage.categorycolor, color: result.node.archiveImage.categorytextcolor}} 
                                className='absolute px-3 py-[2px] text-[12px] font-semibold bottom-0'>{result.node.archiveImage[`${setLang}categoryname`]}</p>
                            <LazyLoad className='' offset={300}>
                              <img className="" alt={result.node.archiveImage.categoryslug} src={result.node.archiveImage.categoryimage}/>
                            </LazyLoad>
                          </div>
                          {/* タイトル部分 */}
                          <div className="w-full">
                            <p className={"CampP text-base font-bold pt-[4px] pb-2 h-[100%] max-h-[58px] min-h-[58px] overflow-hidden"}>
                              <span className='hidden md:block'>{setLang === 'ja'? shortText(parse(result.node.title), setLen): parse(result.node.title)}</span>
                              <span className='md:hidden'>{parse(result.node.title)}</span>
                            </p>
                            <p className={"CampP text-[10px]  mb-4  md:mb-0  flex "} >
                              <span className="pr-5">{postLang["common-txt2"]}{formatDate(result.node.date, setLang)}</span>
                            </p>
                          </div>
                        </a>
                      </article>
                    </li>
                  )}
                </>
              );} return elements; })()}
            </div>
            
            {/* 追加表示/非表示ボタン */}
            {getData.dataArray.length !== 1?
              <button onClick={handleClick} className='flex justify-center max-w-[300px] rounded bg-[#047DFB] hover:bg-[#055BB7] border-solid w-full mx-auto py-6 mb-10'>
                <p className='font-bold text-lg text-white flex justify-between'>
                  <img className="w-[30px] pr-[10px]" src={kijimore} alt='add disp'></img>
                  {count !== getData.dataArray.length - 1 ? postLang["blog-txt7"] : postLang["blog-txt8"]}
                </p>
              </button>: <></>
            }
            
            {/* バナー画像 */}
            <p className="mt-5 md:mb-5 mb-0 px-6">
              <a href={pageBannerData[0].url} className=" transition duration-300 ease-out hover:opacity-[0.6]">
                <LazyLoad className='' offset={300}><img className="page_bn_link1" alt={pageBannerData[0].class} src={pageBannerData[0].pcimage}/></LazyLoad>
              </a>
            </p>
          </div>

          {/* ２カラム カテゴリフィルター */}
          <div className="md:max-w-[250px] w-full mt-5 md:px-0 px-6 ">

            {/* サイドバナー画像 */}
            <p className="md:mb-5 mb-10">
              {pageBannerData.map((result, index) =>
                <a href={result.url} className={`transition duration-300 ease-out hover:opacity-[0.6] ${index === 0? 'hidden': ''}`}>
                  <LazyLoad className='' offset={300}><img className="page_bn_link2 mb-3" alt={result.class} src={result.pcimage}/></LazyLoad>
                </a>
              )}
            </p>
            
            {/* カテゴリ一覧 */}
            <h3 className="bg-[#067CFA] text-white text-center px-6 py-3 mb-5 font-semibold">{postLang["blog-txt4"]}</h3>
            {getData.categoryArray.map((result) => 
              <div className={`min-w-[60px] mb-3 md:min-w-[36px] py-3 md:py-0 cat_btn bg-white hover:bg-[#F7F7F7] border-[0.5px] border-[#55554C] hover:border-[#F7F7F7] relative`}>
                <span style={{ backgroundColor: result.color}} className={`w-[2px] h-[70%] top-[15%] absolute left-2 block`}></span>
                <a href={result.url} className='h-full w-full flex items-center py-3 justify-center px-2'>
                  {result.name}　＞
                </a>
              </div>
            )}

            {/* 最新記事 */}
            <h3 className="mt-8 bg-[#067CFA] text-white text-center px-6 py-3 mb-5 font-semibold">{postLang["blog-txt5"]}</h3>
            <div className="mx-auto max-w-[740px] md:max-w-[700px] pb-4 md:flex md:flex-wrap md:justify-start items-center">
              {getData.latestData.map((result) =>
                <li className="list-none mb-5">
                  <a href={`${langUrl}blog/${result.node.slug}/`} className="">
                    {/* 画像部分 */}
                    <div className="relative w-full overflow-hidden aspect-[4/1.5]">
                      <p style={{ backgroundColor: result.node.archiveImage.categorycolor, color: result.node.archiveImage.categorytextcolor}} 
                          className='absolute px-2 text-[11px] font-semibold py-[5px] bottom-0'>{result.node.archiveImage[`${setLang}categoryname`]}</p>
                      <LazyLoad className='' offset={300}>
                        <img className="" alt={result.node.archiveImage.categoryslug} src={result.node.archiveImage.categoryimage}/>
                      </LazyLoad>
                    </div>
                    {/* タイトル部分 */}
                    <div className="pb-2">
                      <p className="py-[4px]   font-semibold md:text-[15px] text-[16px]">{parse(result.node.title)}</p>
                      <p className="text-[10px] pt-[2px]">{`${postLang["blog-txt9"]}：${formatDate(result.node.modified, setLang)}`}</p>
                    </div>
                  </a>
                </li>
              )}
            </div>
            

            {/* 人気の記事 */}
            <h3 className=" bg-[#067CFA] text-white text-center px-6 py-3 mb-5 font-semibold">{postLang["blog-txt6"]}</h3>
            <div className="mx-auto max-w-[740px] md:max-w-[700px] pb-4 md:flex md:flex-wrap md:justify-start items-center">
              {getData.PopularData.map((result) =>
                <li className="list-none mb-5">
                  <a href={`${langUrl}blog/${result.node.slug}/`} className="">
                    {/* 画像部分 */}
                    <div className="relative w-full overflow-hidden aspect-[4/1.5]">
                      <p style={{ backgroundColor: result.node.archiveImage.categorycolor, color: result.node.archiveImage.categorytextcolor}} 
                          className='absolute px-2 text-[11px] font-semibold py-[5px] bottom-0'>{result.node.archiveImage[`${setLang}categoryname`]}</p>
                      <LazyLoad className='' offset={300}>
                        <img className="" alt={result.node.archiveImage.categoryslug} src={result.node.archiveImage.categoryimage}/>
                      </LazyLoad>
                    </div>
                    {/* タイトル部分 */}
                    <div className="pb-2">
                      <p className="py-[4px] font-semibold md:text-[15px] text-[16px]">{parse(result.node.title)}</p>
                      <p className="text-[10px] pt-[2px]">{`${postLang["blog-txt9"]}：${formatDate(result.node.modified, setLang)}`}</p>
                    </div>
                  </a>
                </li>
              )}
            </div>
          </div>
        </div>

        <div className="Feature pt-5 mt-10 bg-fixed">
          <div className="py-14 py-14 md:py-10 md:py-10">
            <div className="md:mx-auto max-w-5xl flex justify-center">
              <a href={signPath1} className="rounded block md:max-w-xs mx-5 text-center text-white font-bold px-16 my-2 bg-black hover:scale-[1.05] pt-[18px] pb-[20px] duration-300 ">{postLang["open"]}</a>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}
export default BlogPostArchive